import Vue from 'vue'
import axios from 'axios'

import _ from 'lodash'

export const api = axios.create({
  baseURL: process.env.LOCAL ? 'http://localhost:3000/' : window.location.origin.replace('https://', 'https://api.')
})

api.interceptors.request.use(
  async (request) => {
    const accessToken = await Vue.prototype.$auth.getAccessToken()
    request.headers = { Authorization: `Bearer ${accessToken}`, ...request.headers }
    return request
  },
  (error) => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  async (response) => {
    return response
  },
  (error) => {
    // bypass for specs fetch 404 errors
    if (_.get(error, ['response', 'status']) === 404 && _.get(error, ['config', 'url']).includes('/specs/')) {
      return Promise.reject(error)
    }
    Vue.prototype.$snackbar({
      content: `ERROR: ${_.get(error, ['response', 'data', 'message'], error.message)}`,
      color: 'error',
      iconClose: true,
      transition: 'slide-y-reverse-transition'
    })
    return Promise.reject(error)
  }
)

Vue.prototype.$api = api
